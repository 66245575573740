import {useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {doGet} from "./rest";
import Heading from "./Heading";
import CopyFooter from "./CopyFooter";
import Player from "./Player";
import ReactPlayer from "react-player";
import React from 'react';
import {Loading} from "./Loading";
import {hasAuthority} from "./MyCookies";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVideoCamera} from "@fortawesome/free-solid-svg-icons";
import ReactHlsPlayer from "@panelist/react-hls-player";
function Media(){

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [url, setUrl] = useState(false);
    const [user, loading] = useAuthState(auth);
    const [media, setMedia] = useState({});
    const [count, setCount] = useState(0);
    const [mediaAdmin, setMediaAdmin] = useState(false)
    const [liveStrea, setLiveStream] = useState(false)
    const [streamUrl, setStreamUrl] = useState("")
    const navigate = useNavigate();
    useEffect(()=> {
        if (loading) {
            return;
        }
        if (!user) return navigate("/");
        if (count < 1) {
        doGet("media/new-service").then((result) => {
            setMedia(result);
            setIsLoading(false);
        });
        hasAuthority("ROLE_media").then((res)=>{
            setMediaAdmin(res)
        })
        setCount(1)
    }
    }, [user, loading, navigate])

    const togglePopup = (url) => {
        setUrl(url);
        setIsOpen(!isOpen);
    }

    const openLiveStream = (url) =>{
        setStreamUrl(url)
        setLiveStream(!liveStrea);
    }

    const renderBody = () =>{
        return Object.keys(media).map((item, index) => {
            if(item === "videos") {
                return Object.keys(media.videos).map((item, )=>{
                        return (<div>
                            <h2 dangerouslySetInnerHTML={{__html: item}}></h2>
                            {media.videos[item].map(items => (
                                <div className={"flex-filefolders"}><a href="javascript:void(0)"
                                                                       onClick={() => togglePopup(items.url)}>{items.title}</a>
                                </div>
                            ))}
                            <br/>
                        </div>)
                }
                );
            }else{
                    return (<div>
                        <h2>GSV - TV</h2>
                        {media.tv.map(items => (
                            <div className={"flex-filefolders"}><a href="javascript:void(0)"
                                                                   onClick={() => openLiveStream(items.url)}>{items.name}</a>
                            </div>
                        ))}
                        <br/>
                    </div>)
            }
        })
    }

    return (
        <div>
            <div className="form">
                {Heading("Media Gallery")}

            <div className="login">
                {isLoading ? Loading():renderBody()}

            </div>

            </div>
            {mediaAdmin ? <div className="sign logout-container flex">
                <a className={"button-icon"} href="/add-media">
                    <FontAwesomeIcon icon={faVideoCamera}></FontAwesomeIcon><br/>
                    Add Media
                </a>
            </div> : ""}
            {CopyFooter()}
            {isOpen && <Player
                content={<>
                    <ReactPlayer
                        url = {url}
                        playing = {true}
                        ></ReactPlayer>
                </>}
                handleClose={togglePopup}
            ></Player>
            }
            {liveStrea && <Player
                content={<>
                    <ReactHlsPlayer
                        src={streamUrl}
                        autoPlay={true}
                        width="100%"
                        height="auto"
                    />
                </>}
                handleClose={openLiveStream}
            ></Player>
            }
        </div>
    )
}

export default Media
